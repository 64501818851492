import _ from 'lodash'
import moment from 'moment-timezone'
import Radium from 'radium'
import { Component } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { selectLanguageStrings, selectCalculatedLanguageStrings, selectLanguageDateFields } from 'widget/dining/selectors/languageSelectors'
import { selectors as upsellsSelectors } from 'widget/modules/upsells'
import Button from '../../../../lib/Buttons/Button'
import PromoCodeButton from '../../../../lib/Buttons/PromoCodeButton'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { shortDayFormat, timeFormat } from '../../../scripts/utils/constants'
import { verifyPromoCode, changeFormField, removePromoCode } from '../actions/forms'
import { toggleField, toggleModalDisplay } from '../actions/navigation'
import styles from '../assets/styles/checkout'
import {
  selectedBaseData,
  selectIsPaymentInfoRequired,
  selectPricingData,
  selectHasSubtotal,
  getGratuityFields,
  selectIsUsingGiftCard,
} from '../payments/paymentSelectors'
import { modalTypes } from '../utils/constantTypes'
import { selectTimeSlotEntity } from '../utils/selectors'
import { calculateCategoryFees } from '../utils/calculateFees'

class CheckoutSummaryComponent extends Component {
  constructor(props) {
    super(props)
    this.handlePromoCodeChange = this.props.changeFormField.bind(this, 'promoCode')
    this.handleGratuityChange = this.props.changeFormField.bind(this, 'selectedGratuityCharge')
    this.toggleBookingPolicyDisplay = this.props.toggleModalDisplay.bind(this, modalTypes.BOOKING_POLICY)
    this.toggleCustomCheckoutPolicyDisplay = this.props.toggleModalDisplay.bind(this, modalTypes.CUSTOM_CHECKOUT_POLICY)
    this.toggleVenueGroupMarketingOptInPolicyDisplay = this.props.toggleModalDisplay.bind(
      this,
      modalTypes.VENUE_GROUP_MARKETING_OPT_IN_POLICY
    )
    this.toggleVenueSpecificMarketingOptInPolicyDisplay = this.props.toggleModalDisplay.bind(
      this,
      modalTypes.VENUE_SPECIFIC_MARKETING_OPT_IN_POLICY
    )
    this.toggleVenueSmsMarketingOptInPolicyDisplay = this.props.toggleModalDisplay.bind(this, modalTypes.VENUE_SMS_MARKETING_OPT_IN_POLICY)
    this.toggleReservationSmsOptInPolicyDisplay = this.props.toggleModalDisplay.bind(this, modalTypes.RESERVATION_SMS_OPT_IN_POLICY)
    this.toggleDietRestrictionsModal = this.props.toggleModalDisplay.bind(this, modalTypes.DIET_TAG_SELECT)
    this.toggleSpecialOccasionsModal = this.props.toggleModalDisplay.bind(this, modalTypes.OCCASIONS_TAG_SELECT)
    this.toggleReservationNotesModal = this.props.toggleModalDisplay.bind(this, modalTypes.RESERVATION_NOTES)
    this.buildTagDisplay = this.buildTagDisplay.bind(this)
  }

  getDietaryOptinStyle(ignoreError = false) {
    const result =
      ignoreError || this.props.dietaryOptinStatus !== 'invalid'
        ? styles.policyLine
        : { ...styles.policyLine, color: this.props.colorError }
    delete result.height
    return result
  }

  getBookingPolicyStyle() {
    return this.props.bookingPolicyStatus !== 'invalid' ? styles.policyLine : { ...styles.policyLine, color: this.props.colorError }
  }

  getCustomCheckoutPolicyStyle() {
    return this.props.customCheckoutPolicyStatus !== 'invalid' ? styles.policyLine : { ...styles.policyLine, color: this.props.colorError }
  }

  getAgePolicyStyle() {
    return this.props.agePolicyStatus !== 'invalid' ? styles.policyLine : { ...styles.policyLine, color: this.props.colorError }
  }

  getCheckBoxElement(value, required = false, ariaLabel = null) {
    const icon = this.props[value] ? <i style={styles.checkIcon} className="fa fa-check fa-fw" aria-hidden="true" /> : null

    const clickValueToFunction = {
      agreedToBookingPolicy: this.props.onBookingPolicyClick,
      agreedToVenueGroupMarketingOptIn: this.props.onVenueGroupMarketingOptInClick,
      agreedToVenueSpecificMarketingOptIn: this.props.onVenueSpecificMarketingOptInClick,
      agreedToVenueSmsMarketingOptIn: this.props.onVenueSmsMarketingOptInClick,
      agreedToReservationSmsOptIn: this.props.onReservationSmsOptInClick,
      agreedToTailoredCommunicationOptIn: this.props.onTailoredCommunicationOptInClick,
      agreedToAboveAgeConsentOn: this.props.onAboveAgeConsentClick,
      agreedToDietaryGdprOptinOn: this.props.onAgreedToDietaryOptinClick,
      agreedCustomCheckoutPolicy: this.props.onCustomCheckoutPolicyClick,
    }

    return (
      <button
        type="button"
        style={[
          styles.checkbox,
          {
            borderColor: this.props.fontsColorCheckoutActive,
            color: this.props.fontsColorCheckoutActive,
          },
        ]}
        onClick={clickValueToFunction[value]}
        aria-label={ariaLabel || _.startCase(value)}
        data-test={`sr-${value}-checkbox`}
      >
        {icon}
      </button>
    )
  }

  buildPriceLine(strA, strB, isPromo, testId) {
    return (
      <div>
        <div style={[styles.breakdown, styles.floatLeft, isPromo && styles.promoDiscount]}>{strA}</div>
        <div style={[styles.breakdown, styles.floatRight, isPromo && { fontStyle: 'italic' }]} data-test={testId}>
          {strB}
        </div>
      </div>
    )
  }

  buildGratuityPriceAndDropDownLine(strA, strB, selectedGratuityCharge) {
    const customOptions = _.map(_.range(40), (val, index) => (
      <option key={index} value={val}>
        {val}%
      </option>
    ))
    const gratuityOptions = []
    for (let i = 0; i < 40; i += 1) {
      gratuityOptions.push({ name: i, value: i })
    }
    const selectedGratuity = {
      name: selectedGratuityCharge,
      value: selectedGratuityCharge,
    }
    return (
      <div id="sr-gratuity-line">
        <div style={[styles.breakdown, styles.floatLeft]}>
          {strA}
          <select
            onChange={this.handleGratuityChange}
            style={{
              WebkitAppearance: 'inherit',
              MozAppearance: 'none',
              textIndent: 2,
              width: 60,
              height: 25,
              textOverflow: '',
              color: 'inherit',
              borderColor: this.props.formErrors.selectedGratuityCharge ? '#d01a21' : '#a6a6a6',
              borderRadius: 'inherit',
              backgroundColor: 'transparent',
              backgroundImage:
                'linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),linear-gradient(to right, #ccc, #ccc)',
              backgroundPosition: 'calc(100% - 10px) calc(.8em), calc(100% - 5px) calc(.8em), calc(100% - 5px) 2em',
              backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
              backgroundRepeat: 'no-repeat',
              marginLeft: 10,
            }}
            value={selectedGratuityCharge}
          >
            <option value="" selected disabled>
              select
            </option>
            {customOptions}
          </select>
        </div>
        <div style={[styles.breakdown, styles.floatRight]}>{strB}</div>
      </div>
    )
  }

  buildDateTimeDetails() {
    const { selectedTimeSlot, actual, isModifyResMode, selectedAvailability, locale, selectedLanguage } = this.props
    const shouldTranslateDates = !_.isNil(selectedLanguage)

    const dateFormat = shortDayFormat[locale] || shortDayFormat.default
    const timeDisplayFormat = timeFormat[locale] || timeFormat.default
    const dateStr = selectedTimeSlot.format(dateFormat)
    const timeStr = selectedTimeSlot.format(timeDisplayFormat)

    let originalDateStr = null
    let originalStrToCompare = null
    if (isModifyResMode) {
      const actual_date = actual.get('date')
      originalStrToCompare = moment(actual_date).format(dateFormat)
      if (shouldTranslateDates) {
        originalDateStr = moment(actual_date).locale(selectedLanguage).format(dateFormat)
      } else {
        originalDateStr = originalStrToCompare
      }
    }

    const originalTimeStr = isModifyResMode && moment(actual.get('arrival_time_display'), ['h:mm A']).format(timeDisplayFormat)
    const isDateChanged = dateStr !== originalStrToCompare
    const isTimeChanged = timeStr !== originalTimeStr
    const isResChanged = isModifyResMode && (isDateChanged || isTimeChanged)
    const dot = <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>

    const timeDisplay = () =>
      timeStr +
      (selectedAvailability && selectedAvailability.confirmationIncludeEndTime
        ? ` - ${selectedTimeSlot.clone().add(selectedAvailability.duration, 'minutes').format(timeDisplayFormat)}`
        : '')
    const newResTimes = () => (
      <div style={styles.inline}>
        <span>{dateStr}</span>
        {dot}
        <span>{timeDisplay()}</span>
      </div>
    )
    const bothChanged = () => (
      <div style={styles.inline}>
        <div style={[styles.inline, styles.crossedOutText, { marginRight: '16px' }]}>
          <span>{originalDateStr}</span>
          {dot}
          <span>{originalTimeStr}</span>
        </div>
        {newResTimes()}
      </div>
    )
    const justDateChanged = () => (
      <div style={styles.inline}>
        <span style={[styles.crossedOutText, { marginRight: '16px' }]}>{originalDateStr}</span>
        <span>{dateStr}</span>
        {dot}
        <span>{timeDisplay()}</span>
      </div>
    )
    const justTimeChanged = () => (
      <div style={styles.inline}>
        <span>{dateStr}</span>
        {dot}
        <span style={[styles.crossedOutText, { marginRight: '16px' }]}>{originalTimeStr}</span>
        <span>{timeDisplay()}</span>
      </div>
    )
    let resDetails

    if (isResChanged) {
      if (isDateChanged && isTimeChanged) {
        resDetails = bothChanged()
      } else if (isDateChanged) {
        resDetails = justDateChanged()
      } else {
        resDetails = justTimeChanged()
      }
    } else {
      resDetails = newResTimes()
    }

    return (
      <div>
        {resDetails}
        {!isModifyResMode && selectedAvailability && selectedAvailability.publicTimeSlotDescription && (
          <span style={styles.inline}>
            {dot}
            {this.props.selectedAvailability.publicTimeSlotDescription}
          </span>
        )}
      </div>
    )
  }

  buildTagDisplay(tagGroup, label) {
    const tagList = _.map(_.keys(tagGroup.selectedTags), tagName => (
      <div
        key={tagName}
        style={[
          styles.miniTag,
          {
            color: this.props.fontsColorButton,
            backgroundColor: this.props.colorPrimary,
          },
        ]}
        data-test="sr-selected-tag"
      >
        {this.props.tagTranslations[tagName] || tagGroup.tagNameDisplays[tagName] || tagName}
      </div>
    ))
    const labelElement = !label ? null : <div style={[styles.tinyFont, { color: this.props.fontsColorCheckoutInactive }]}>{label}</div>
    return _.isEmpty(tagList) ? null : (
      <div style={styles.selectedTagdisplay}>
        {labelElement}
        {tagList}
      </div>
    )
  }

  renderUpsellsInventory = () => {
    const {
      inventoryEdits,
      inventoryEntities,
      currencySymbol,
      isFeesInPriceDisplayed,
      categoriesEntities,
      defaultServiceCharge,
      defaultGratuity,
    } = this.props
    const buildUpsellPriceLine = (strA, strB, testId) => (
      <div data-test={testId}>
        <div style={[styles.upsellbreakdown, styles.floatLeft]}>{strA}</div>
        <div style={[styles.upsellbreakdown, styles.floatRight]}>{strB}</div>
      </div>
    )
    return Object.keys(inventoryEdits).map(inventoryId => {
      const showPriceLine = inventoryEdits[inventoryId].quantity > 0
      const showPrice = !inventoryEntities[inventoryId].isPriceHidden && inventoryEntities[inventoryId].price >= 0
      const strA = (
        <>
          <span style={{ marginRight: '16px' }}>{inventoryEdits[inventoryId].quantity}x</span>
          <span style={{ maxWidth: '400px', width: '100%', display: 'inline-block' }}>{inventoryEntities[inventoryId].name}</span>
        </>
      )
      let fees = 0
      if (isFeesInPriceDisplayed && categoriesEntities[inventoryEntities[inventoryId].categoryId]) {
        const category = categoriesEntities[inventoryEntities[inventoryId].categoryId]

        fees = calculateCategoryFees(
          isFeesInPriceDisplayed,
          inventoryEntities[inventoryId].price,
          category,
          defaultServiceCharge,
          defaultGratuity
        )
      }
      const total = inventoryEdits[inventoryId].quantity * (inventoryEntities[inventoryId].price + fees)
      const strB = showPrice && (
        <span>
          {currencySymbol} {total.toFixed(2)}
          {fees && fees !== 0 ? ` (Includes ${currencySymbol}${(fees * inventoryEdits[inventoryId].quantity).toFixed(2)} in fees)` : ''}
        </span>
      )
      return showPriceLine && buildUpsellPriceLine(strA, strB, 'sr-upsell-price-value')
    })
  }

  render() {
    const {
      isPaymentInfoRequired,
      isUsingGiftCard,
      pricingData,
      onAboveAgeConsentClick,
      onAgreedToDietaryOptinClick,
      onBookingPolicyClick,
      onCustomCheckoutPolicyClick,
      onReservationSmsOptInClick,
      onTailoredCommunicationOptInClick,
      onVenueGroupMarketingOptInClick,
      onVenueSpecificMarketingOptInClick,
      onVenueSmsMarketingOptInClick,
      verifyPromoCode,
      removePromoCode,
      isPromoCodesEnabled,
      isVerifyingPromoCode,
      validPromoCode,
      ageToConsent,
      displayAboveAgeConsentPolicy,
      displayAgreedToDietaryOptIn,
      displayBookingPolicyInfo,
      isDisplayCustomCheckoutPolicy,
      displayReservationSmsOptInPolicy,
      displayTailoredCommunicationOptInPolicy,
      displayVenueGroupMarketingOptInPolicy,
      displayVenueGroupMarketingPolicyInfo,
      isDisplayCustomCheckoutPolicyInfo,
      displayVenueSpecificMarketingOptInPolicy,
      displayVenueSpecificMarketingPolicyInfo,
      displayVenueSmsMarketingOptInPolicy,
      displayVenueSpecificSmsMarketingPolicyInfo,
      displayGratuityLine,
      displaySelectGratuityLine,
      partySize,
      currencySymbol,
      selectedGratuityCharge,
      upsellTotals,
      hasSubtotal,
      fontsColorPrimary,
      fontsColorCheckoutActive,
      note,
      promoCode,
      mediaUrl,
      fontFamily,
      colorError,
      colorCheckoutCellBackground,
      colorLines,
      dietaryRestrictions,
      dietaryRestrictionsGuest,
      specialOccasions,
      fontsColorCheckoutInactive,
      textSummary,
      textGuestLabel,
      textReservationNotes,
      textUpgrades,
      textServiceCharge,
      textSubtotal,
      textTax,
      textTip,
      textSelectATip,
      textTotal,
      textVenueGroupMarketingOptIn,
      textVenueSpecificMarketingOptIn,
      textVenueSmsMarketingOptIn,
      textReservationSmsOptIn,
      textTailoredCommunicationOptInLabel,
      textTailoredCommunicationOptInHeader,
      textTailoredCommunicationOptInBody,
      textAgreeToPolicy,
      textLabelCustomCheckoutPolicy,
      textDietaryRestrictions,
      textSpecialOccasion,
      textAgeConsent,
      textAddButtonLabel,
      textTagLabelYours,
      textTagLabelYourGuests,
      textPromoLabel,
      textPromoButtonApplyLabel,
      textPromoButtonRemoveLabel,
      isModifyResMode,
      actual,
      textCustomGdprDietaryTagOptinLabel,
      textCustomGdprDietaryTagOptinErrMsg,
      redemptions,
      isModifyResUpgradesMode,
    } = this.props

    const displayOccasionTagLine = specialOccasions && !_.isEmpty(specialOccasions.tags)
    const {
      baseAmount,
      upsellBaseAmount,
      promoCodeDiscount,
      paidInServiceCharge,
      paidInTax,
      paidInGratuity,
      paidInSelectedGratuity,
      finalAmount,
      amountDue,
    } = pricingData
    const displayUpsellsLine = upsellTotals.quantity > 0
    const displayDiscountLine = !!validPromoCode || !!promoCodeDiscount
    const displayServiceChargeLine = !!paidInServiceCharge
    const displayTaxLine = !!paidInTax

    const displayBaseLine =
      (!isModifyResUpgradesMode && !!baseAmount) ||
      displayUpsellsLine ||
      displayDiscountLine ||
      displayTaxLine ||
      displayGratuityLine ||
      displaySelectGratuityLine ||
      displayServiceChargeLine

    const textInputProps = {
      fontFamily,
      placeholderColor: fontsColorCheckoutInactive,
      textColor: fontsColorCheckoutActive,
      errorColor: colorError,
    }

    const displayDietRestrictions = dietaryRestrictions && !_.isEmpty(dietaryRestrictions.tags)
    const displayDietRestrictionsGuest = dietaryRestrictionsGuest && !_.isEmpty(dietaryRestrictionsGuest.tags)

    const originalNumGuests = isModifyResMode && actual && actual.get('total_guests')
    const isNumGuestsChanged = isModifyResMode && partySize !== originalNumGuests
    const addButtonLabel = `+ ${textAddButtonLabel}`
    const displayGiftCardsLine = Boolean(_.size(redemptions)) && isUsingGiftCard
    return (
      <div style={styles.summaryWrapper}>
        <div style={styles.labelWrapper}>
          <span style={[styles.sectionLabel, { color: fontsColorPrimary }]}>{textSummary}</span>
        </div>
        <div style={styles.infoForm}>
          <div
            style={[
              styles.summaryLine,
              styles.topBorderRadius,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
            ]}
            data-test="sr-venue-name-label-row"
          >
            <i style={styles.lineIcon} className="fa fa-map-marker fa-fw" aria-hidden="true" />
            <span style={styles.summaryText}>{this.props.venueName}</span>
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div
            style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}
            data-test="sr-date-time-label-row"
          >
            <i style={styles.lineIcon} className="fa fa-time-8 fa-fw" aria-hidden="true" />
            <span style={styles.summaryText}>{this.buildDateTimeDetails()}</span>
          </div>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          <div
            style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}
            data-test="sr-guest-count-row"
          >
            <i style={styles.lineIcon} className="fa fa-users fa-fw" aria-hidden="true" />
            <div style={styles.summaryText}>
              {isNumGuestsChanged && (
                <span style={[styles.crossedOutText, { marginRight: '16px' }]}>
                  {originalNumGuests} {textGuestLabel}
                </span>
              )}
              <span>
                {partySize} {textGuestLabel}
              </span>
            </div>
          </div>
          {displayUpsellsLine && (
            <>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
              <div
                style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}
                data-test="sr-upsells-list-row"
              >
                <i style={styles.lineIcon} className="fa fa-star fa-fw" aria-hidden="true" />
                <div style={[styles.summaryText, { marginBottom: '16px' }]}>
                  <span>{textUpgrades}</span>
                </div>
                <div style={{ paddingLeft: '7%' }}>{this.renderUpsellsInventory()}</div>
              </div>
            </>
          )}
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          {(displayDietRestrictions || displayDietRestrictionsGuest) && (
            <div>
              <div style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
                <i style={styles.lineIcon} className="fa fa-tag fa-fw" aria-hidden="true" />
                <span style={[styles.summaryText, styles.adjustedText]}>{textDietaryRestrictions}</span>
                <Button
                  withDefaultOutline
                  innerElement={addButtonLabel}
                  onClick={this.toggleDietRestrictionsModal}
                  activeStyling
                  style={{
                    ...styles.addButton,
                    color: fontsColorCheckoutActive,
                    borderColor: fontsColorCheckoutActive,
                    textAlign: 'center',
                    lineHeight: '1em',
                  }}
                  testId="sr-dietary-tag-add-button"
                />
                {displayDietRestrictions && this.buildTagDisplay(dietaryRestrictions, textTagLabelYours)}
                {displayAgreedToDietaryOptIn && !_.isEmpty(dietaryRestrictions.selectedTags) && (
                  <div>
                    <div
                      style={[
                        styles.summaryLine,
                        { color: fontsColorCheckoutActive },
                        { width: '95%' },
                        { marginLeft: '37px' },
                        {
                          paddingTop: '8px',
                          paddingBottom: '12px',
                          paddingLeft: '4px',
                        },
                      ]}
                    >
                      {this.getCheckBoxElement('agreedToDietaryGdprOptinOn', true, 'GDPR consent to store sensitive dietary information')}
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <span
                        style={{
                          ...this.getDietaryOptinStyle(true),
                          color: fontsColorCheckoutActive,
                          maxWidth: '90%',
                        }}
                        onClick={onAgreedToDietaryOptinClick}
                      >
                        {textCustomGdprDietaryTagOptinLabel}
                      </span>
                    </div>
                    {this.props.dietaryOptinStatus === 'invalid' && (
                      <div
                        style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { marginLeft: '39px' }, { paddingLeft: '0px' }]}
                      >
                        <div
                          style={{
                            ...this.getDietaryOptinStyle(),
                            color: colorError,
                            lineHeight: '14px',
                            fontStyle: 'italic',
                            fontSize: '12px',
                            maxWidth: '95%',
                          }}
                        >
                          {textCustomGdprDietaryTagOptinErrMsg}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {displayDietRestrictionsGuest && this.buildTagDisplay(dietaryRestrictionsGuest, textTagLabelYourGuests)}
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            </div>
          )}

          {displayOccasionTagLine && (
            <div>
              <div style={[styles.summaryLine, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
                <i style={styles.lineIcon} className="fa fa-tag fa-fw" aria-hidden="true" />
                <span style={[styles.summaryText, styles.adjustedText]}>{textSpecialOccasion}</span>
                <Button
                  withDefaultOutline
                  innerElement={addButtonLabel}
                  onClick={this.toggleSpecialOccasionsModal}
                  activeStyling
                  style={{
                    ...styles.addButton,
                    color: fontsColorCheckoutActive,
                    borderColor: fontsColorCheckoutActive,
                    textAlign: 'center',
                    lineHeight: '1em',
                  }}
                  testId="sr-special-occasion-tag-add-button"
                />
                {this.buildTagDisplay(specialOccasions)}
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            </div>
          )}
          <button
            type="button"
            onClick={this.toggleReservationNotesModal}
            style={[
              styles.summaryLine,
              styles.activeLine,
              styles.buttonLine,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
            ]}
            data-test="sr-guest-notes"
          >
            <i style={[styles.lineIcon, styles.shiftUp]} className="fa fa-notes fa-fw" aria-hidden="true" />
            <span style={styles.summaryText}>{textReservationNotes}</span>
            {note && <div style={styles.noteDisplay}>{note}</div>}
          </button>
          <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
          {hasSubtotal && isPromoCodesEnabled && (
            <div>
              <div style={[styles.promoCodeLine, { backgroundColor: colorCheckoutCellBackground }]}>
                <TextInput
                  placeholder={textPromoLabel}
                  limitType="none"
                  value={promoCode}
                  charLimit={50}
                  onChange={this.handlePromoCodeChange}
                  disabled={!!validPromoCode}
                  style={styles.promoCodeField}
                  {...textInputProps}
                  testId="sr-promo-code-field"
                />
                {promoCode && (
                  <PromoCodeButton
                    onApplyClick={verifyPromoCode}
                    onRemoveClick={removePromoCode}
                    validPromoCode={validPromoCode}
                    isVerifyingPromoCode={isVerifyingPromoCode}
                    mediaUrl={mediaUrl}
                    applyButtonLabel={textPromoButtonApplyLabel}
                    removeButtonLabel={textPromoButtonRemoveLabel}
                    colorError={textInputProps.errorColor}
                    fontsColorCheckoutInactive={textInputProps.placeholderColor}
                  />
                )}
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            </div>
          )}
          {hasSubtotal && (
            <div>
              <div style={[styles.priceBreakdown, { color: fontsColorCheckoutActive }, { backgroundColor: colorCheckoutCellBackground }]}>
                {displayBaseLine &&
                  this.buildPriceLine(textSubtotal, `${currencySymbol} ${baseAmount.toFixed(2)}`, false, 'sr-reservation-price-value')}
                {displayUpsellsLine &&
                  upsellBaseAmount > 0 &&
                  this.buildPriceLine(textUpgrades, `${currencySymbol} ${upsellBaseAmount.toFixed(2)}`, false, 'sr-upgrades-price-value')}
                {displayDiscountLine &&
                  this.buildPriceLine(
                    `${textPromoLabel.toLowerCase()} (${promoCode})`,
                    `-${currencySymbol} ${promoCodeDiscount.toFixed(2)}`,
                    true,
                    'sr-discount-value'
                  )}
                {displayServiceChargeLine &&
                  this.buildPriceLine(
                    textServiceCharge,
                    `${currencySymbol} ${paidInServiceCharge.toFixed(2)}`,
                    false,
                    'sr-service-charge-value'
                  )}
                {displayTaxLine && this.buildPriceLine(textTax, `${currencySymbol} ${paidInTax.toFixed(2)}`, false, 'sr-tax-value')}
                {displayGratuityLine &&
                  this.buildPriceLine(textTip, `${currencySymbol} ${paidInGratuity.toFixed(2)}`, false, 'sr-tip-value')}
                {displaySelectGratuityLine &&
                  this.buildGratuityPriceAndDropDownLine(
                    textSelectATip,
                    `${currencySymbol} ${paidInSelectedGratuity.toFixed(2)}`,
                    selectedGratuityCharge
                  )}
                <div style={[displayGiftCardsLine ? styles.breakdown : styles.lastBreakdown, styles.floatLeft]}>{textTotal}</div>
                <div
                  style={[displayGiftCardsLine ? styles.breakdown : styles.lastBreakdown, styles.floatRight]}
                  data-test="sr-upgrades-order-total"
                >
                  {`${currencySymbol} ${finalAmount.toFixed(2)}`}
                </div>
                {displayGiftCardsLine &&
                  _.map(redemptions, redemption => {
                    if (redemption) {
                      const { redemptionCardId, amountToRedeem } = redemption
                      return this.buildPriceLine(
                        `gift card (...${redemptionCardId.slice(redemptionCardId.length - 4)})`,
                        `- ${currencySymbol} ${amountToRedeem.toFixed(2)}`
                      )
                    }

                    return undefined
                  })}
                {!_.isNil(amountDue) && displayGiftCardsLine && (
                  <div>
                    <div style={[styles.lastBreakdown, styles.floatLeft]}>amount due</div>
                    <div style={[styles.lastBreakdown, styles.floatRight]}>{`${currencySymbol} ${amountDue.toFixed(2)}`}</div>
                  </div>
                )}
              </div>
              <hr style={[styles.formLineSeperator, { borderColor: colorLines }]} />
            </div>
          )}
          <div
            style={[
              styles.policySection,
              styles.bottomBorderRadius,
              { color: fontsColorCheckoutActive },
              { backgroundColor: colorCheckoutCellBackground },
            ]}
          >
            {isPaymentInfoRequired && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToBookingPolicy', true)}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span style={this.getBookingPolicyStyle()} onClick={onBookingPolicyClick}>
                  {textAgreeToPolicy} *
                </span>
                {displayBookingPolicyInfo && (
                  <button
                    type="button"
                    style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.toggleBookingPolicyDisplay}
                    aria-hidden="true"
                    aria-label="booking policy info"
                  />
                )}
              </div>
            )}
            {isDisplayCustomCheckoutPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedCustomCheckoutPolicy', true)}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span style={this.getCustomCheckoutPolicyStyle()} onClick={onCustomCheckoutPolicyClick}>
                  {textLabelCustomCheckoutPolicy} *
                </span>
                {isDisplayCustomCheckoutPolicyInfo && (
                  <button
                    type="button"
                    style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.toggleCustomCheckoutPolicyDisplay}
                    aria-hidden="true"
                    aria-label="required policy info"
                  />
                )}
              </div>
            )}
            {displayAboveAgeConsentPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToAboveAgeConsentOn', true)}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span style={this.getAgePolicyStyle()} onClick={onAboveAgeConsentClick}>
                  {textAgeConsent} {ageToConsent} *
                </span>
              </div>
            )}
            {displayVenueSmsMarketingOptInPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToVenueSmsMarketingOptIn')}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span
                  style={{ ...styles.policyLine, width: '75%' }}
                  onClick={onVenueSmsMarketingOptInClick}
                  dangerouslySetInnerHTML={{
                    __html: textVenueSmsMarketingOptIn,
                  }}
                />
                {displayVenueSpecificSmsMarketingPolicyInfo && (
                  <button
                    type="button"
                    style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.toggleVenueSmsMarketingOptInPolicyDisplay}
                    aria-hidden="true"
                    aria-label="venue marketing policy info"
                  />
                )}
              </div>
            )}
            {displayVenueSpecificMarketingOptInPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToVenueSpecificMarketingOptIn')}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span
                  style={{ ...styles.policyLine, width: '75%' }}
                  onClick={onVenueSpecificMarketingOptInClick}
                  dangerouslySetInnerHTML={{
                    __html: textVenueSpecificMarketingOptIn,
                  }}
                />
                {displayVenueSpecificMarketingPolicyInfo && (
                  <button
                    type="button"
                    style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.toggleVenueSpecificMarketingOptInPolicyDisplay}
                    aria-hidden="true"
                    aria-label="venue marketing policy info"
                  />
                )}
              </div>
            )}
            {displayVenueGroupMarketingOptInPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToVenueGroupMarketingOptIn')}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span
                  style={{ ...styles.policyLine, width: '75%' }}
                  onClick={onVenueGroupMarketingOptInClick}
                  dangerouslySetInnerHTML={{
                    __html: textVenueGroupMarketingOptIn,
                  }}
                />
                {displayVenueGroupMarketingPolicyInfo && (
                  <button
                    type="button"
                    style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.toggleVenueGroupMarketingOptInPolicyDisplay}
                    aria-hidden="true"
                    aria-label="venue group marketing policy info"
                  />
                )}
              </div>
            )}
            {displayReservationSmsOptInPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToReservationSmsOptIn')}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span style={{ ...styles.policyLine, width: '78%' }} onClick={onReservationSmsOptInClick}>
                  <IntlProvider locale="en">
                    <FormattedMessage
                      id="textReservationSmsOptIn"
                      // eslint-disable-next-line formatjs/enforce-default-message
                      defaultMessage={textReservationSmsOptIn}
                      values={{
                        i: chunks => <i>{chunks}</i>,
                      }}
                    />
                  </IntlProvider>
                </span>
                <button
                  type="button"
                  style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                  className="fa fa-info-circle fa-fw"
                  onClick={this.toggleReservationSmsOptInPolicyDisplay}
                  aria-hidden="true"
                  aria-label="SMS opt in policy info"
                />
              </div>
            )}
            {displayTailoredCommunicationOptInPolicy && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToTailoredCommunicationOptIn')}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span style={{ ...styles.policyLine, width: '78%' }} onClick={onTailoredCommunicationOptInClick}>
                  <IntlProvider locale="en">
                    <FormattedMessage
                      id="textTailoredCommunicationOptInLabel"
                      // eslint-disable-next-line formatjs/enforce-default-message
                      defaultMessage={textTailoredCommunicationOptInLabel}
                      values={{
                        i: chunks => <i>{chunks}</i>,
                      }}
                    />
                  </IntlProvider>
                </span>
                <button
                  type="button"
                  style={[{ color: fontsColorCheckoutActive }, styles.infoIcon, styles.buttonInfoIcon]}
                  className="fa fa-info-circle fa-fw"
                  onClick={this.toggleTailoredCommunicationOptInPolicyDisplay}
                  aria-hidden="true"
                  aria-label="Tailored Communication opt in policy info"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { accessPersistentId, partySize } = state.search.toJS()
  const { selectedTimeSlot } = selectLanguageDateFields(state)
  const selectedVenue = state.searchResults.get('timeSlotVenue')
  const selectedTime = selectedTimeSlot.format('hh:mm A').replace(/^0+/, '')
  const selectedDate = selectedTimeSlot.format('YYYY-MM-DD')
  const selectedAvailabilityImmutable =
    selectedDate && state.availabilityLookup.getIn([selectedVenue, selectedDate, selectedTime, accessPersistentId])
  const selectedAvailability = selectedAvailabilityImmutable && selectedAvailabilityImmutable.toJS()

  const calcLanguageStrings = selectCalculatedLanguageStrings(state)

  const venueGroupMarketingPolicy = calcLanguageStrings.policyVenueGroupMarketing
  const displayVenueGroupMarketingPolicyInfo = venueGroupMarketingPolicy && !_.isEmpty(venueGroupMarketingPolicy.trim())

  const venueSpecificMarketingPolicy = calcLanguageStrings.policyVenueSpecificMarketing
  const displayVenueSpecificMarketingPolicyInfo = venueSpecificMarketingPolicy && !_.isEmpty(venueSpecificMarketingPolicy.trim())

  const venueSpecificSmsMarketingPolicy = calcLanguageStrings.policyVenueSpecificSmsMarketing
  const displayVenueSpecificSmsMarketingPolicyInfo = venueSpecificSmsMarketingPolicy && !_.isEmpty(venueSpecificSmsMarketingPolicy.trim())

  const bookingPolicy = state.availability.get('cancellationPolicy')
  const displayBookingPolicyInfo = bookingPolicy && !_.isEmpty(bookingPolicy.trim())

  const customCheckoutPolicy = calcLanguageStrings.textCustomCheckoutPolicy
  const isDisplayCustomCheckoutPolicyInfo = customCheckoutPolicy && !_.isEmpty(customCheckoutPolicy.trim())

  const dietaryRestrictionsId = state.entities.tags.getIn(['clientTagGroups', 'dietaryPreference'])
  const dietaryRestrictions = state.entities.tags.getIn(['tagGroups', dietaryRestrictionsId])
  const dietaryRestrictionsGuestId = state.entities.tags.getIn(['reservationTagGroups', 'dietaryPreference'])
  const dietaryRestrictionsGuest = state.entities.tags.getIn(['tagGroups', dietaryRestrictionsGuestId])
  const specialOccasionsId = state.entities.tags.getIn(['reservationTagGroups', 'specialOccasion'])
  const specialOccasions = state.entities.tags.getIn(['tagGroups', specialOccasionsId])

  const timeSlotVenue = state.searchResults.get('timeSlotVenue')
  const timeEntity = selectTimeSlotEntity(state)

  const upsellTotals = upsellsSelectors.selectUpsellTotal(state)
  const languageStrings = selectLanguageStrings(state)
  const validPromoCode = state.formFields.get('validPromoCode')

  const gratuityFields = getGratuityFields(state)

  let tagTranslations = !_.isEmpty(state.languages.selectedLanguage)
    ? state.languages.tagLanguageStrings[state.languages.selectedLanguage]
    : {}
  tagTranslations = _.isNil(tagTranslations) ? {} : tagTranslations

  return {
    hasSubtotal: selectHasSubtotal(state),
    isPaymentInfoRequired: selectIsPaymentInfoRequired(state),
    isUsingGiftCard: selectIsUsingGiftCard(state),
    pricingData: selectPricingData(state),
    basePriceData: selectedBaseData(state),
    selectedTimeSlot,
    partySize,
    tagTranslations,
    venueName: state.search.get('venueMap').get(timeSlotVenue),
    cost: timeEntity.cost,
    taxRate: timeEntity.taxRate,
    displayGratuityLine: gratuityFields.displayGratuityLine,
    displaySelectGratuityLine: gratuityFields.displaySelectGratuityLine,
    gratuity: timeEntity.gratuity,
    selectedGratuityCharge: state.formFields.get('selectedGratuityCharge'),
    chargeType: timeEntity.chargeType,
    currencySymbol: state.venueInfo.currencySymbol,
    note: state.formFields.get('note'),
    promoCode: state.formFields.get('promoCode'),
    autoAddedCreditCardPromoCode: state.formFields.get('autoAddedCreditCardPromoCode'),
    isVerifyingPromoCode: state.formFields.get('isVerifyingPromoCode'),
    validPromoCode,
    isPromoCodesEnabled: state.widgetSettings.enablePromoCodes,
    isFeesInPriceDisplayed: state.widgetSettings.isFeesInPriceDisplayed,
    promoCodeEntity: state.entities.promoCode.get(validPromoCode),
    agreedToBookingPolicy: state.formFields.get('agreedToBookingPolicy'),
    agreedCustomCheckoutPolicy: state.formFields.get('agreedCustomCheckoutPolicy'),
    ageToConsent: state.formFields.get('ageToConsent'),
    agreedToDietaryGdprOptinOn: state.formFields.get('agreedToDietaryGdprOptinOn'),
    agreedToAboveAgeConsentOn: state.formFields.get('agreedToAboveAgeConsentOn'),
    displayAgreedToDietaryOptIn: state.formFields.get('displayAgreedToDietaryOptIn'),
    displayAboveAgeConsentPolicy: state.formFields.get('displayAboveAgeConsentPolicy'),
    isDisplayCustomCheckoutPolicy: state.formFields.get('isDisplayCustomCheckoutPolicy'),
    displayVenueGroupMarketingOptInPolicy: state.formFields.get('displayVenueGroupMarketingOptInPolicy'),
    displayVenueSpecificMarketingOptInPolicy: state.formFields.get('displayVenueSpecificMarketingOptInPolicy'),
    displayVenueSmsMarketingOptInPolicy: state.formFields.get('displayVenueSmsMarketingOptInPolicy'),
    displayReservationSmsOptInPolicy: state.formFields.get('displayReservationSmsOptInPolicy'),
    displayTailoredCommunicationOptInPolicy: state.formFields.get('displayTailoredCommunicationOptInPolicy'),
    agreedToVenueGroupMarketingOptIn: state.formFields.get('agreedToVenueGroupMarketingOptIn'),
    agreedToVenueSpecificMarketingOptIn: state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    agreedToVenueSmsMarketingOptIn: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    agreedToReservationSmsOptIn: state.formFields.get('agreedToReservationSmsOptIn'),
    agreedToTailoredCommunicationOptIn: state.formFields.get('agreedToTailoredCommunicationOptIn'),
    displayVenueGroupMarketingPolicyInfo,
    displayVenueSpecificMarketingPolicyInfo,
    displayVenueSpecificSmsMarketingPolicyInfo,
    displayBookingPolicyInfo,
    isDisplayCustomCheckoutPolicyInfo,
    dietaryRestrictions: state.widgetSettings.enableDietaryRestrictions && dietaryRestrictions ? dietaryRestrictions.toJS() : null,
    dietaryRestrictionsGuest:
      state.widgetSettings.enableDietaryRestrictions && dietaryRestrictionsGuest ? dietaryRestrictionsGuest.toJS() : null,
    specialOccasions: state.widgetSettings.enableSpecialOccasions && specialOccasions ? specialOccasions.toJS() : null,
    fontFamily: state.widgetSettings.font,
    mediaUrl: state.widgetSettings.mediaUrl,
    upsellTotals,
    inventoryEdits: upsellsSelectors.getInventoryEdits(state),
    inventoryEntities: upsellsSelectors.getUpsellInventoryEntities(state),
    categoriesEntities: upsellsSelectors.getUpsellCategoriesEntities(state),
    defaultServiceCharge: timeEntity.defaultServiceCharge,
    defaultGratuity: timeEntity.defaultGratuity,
    // colors
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    colorError: state.widgetSettings.colorError,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutCellBackground,
    colorLines: state.widgetSettings.colorLines,
    colorPrimary: state.widgetSettings.colorPrimary,
    // errors
    bookingPolicyStatus: state.formFields.get('bookingPolicyStatus'),
    customCheckoutPolicyStatus: state.formFields.get('customCheckoutPolicyStatus'),
    agePolicyStatus: state.formFields.get('agePolicyStatus'),
    dietaryOptinStatus: state.formFields.get('dietaryOptinStatus'),
    formErrors: state.formFields.get('formErrors').toJS(),
    selectedAvailability,
    redemptions: state.redemption.redemptions,
    // text
    textSummary: languageStrings.textSummary,
    textGuestLabel: calcLanguageStrings.textGuestLabel,
    textReservationNotes: languageStrings.textReservationNotes,
    textUpgrades: languageStrings.textUpgrades,
    textServiceCharge: languageStrings.textServiceCharge,
    textSubtotal: languageStrings.textSubtotal,
    textTax: languageStrings.textTax,
    textTip: languageStrings.textTip,
    textSelectATip: languageStrings.textSelectATip,
    textTotal: languageStrings.textTotal,
    textVenueGroupMarketingOptIn: calcLanguageStrings.textVenueGroupMarketingOptIn,
    textVenueSpecificMarketingOptIn: calcLanguageStrings.textVenueSpecificMarketingOptIn,
    textVenueSmsMarketingOptIn: calcLanguageStrings.textVenueSmsMarketingOptIn,
    textReservationSmsOptIn: calcLanguageStrings.textReservationSmsOptIn,
    textTailoredCommunicationOptInLabel: calcLanguageStrings.textTailoredCommunicationOptInLabel,
    textTailoredCommunicationOptInHeader: calcLanguageStrings.textTailoredCommunicationOptInHeader,
    textTailoredCommunicationOptInBody: calcLanguageStrings.textTailoredCommunicationOptInBody,
    textAgreeToPolicy: languageStrings.textAgreeToPolicy,
    textDietaryRestrictions: languageStrings.textDietaryRestrictions,
    textSpecialOccasion: languageStrings.textSpecialOccasion,
    textAgeConsent: languageStrings.textAgeConsent,
    textAddButtonLabel: languageStrings.textAddButtonLabel,
    textTagLabelYours: languageStrings.textTagLabelYours,
    textTagLabelYourGuests: languageStrings.textTagLabelYourGuests,
    textPromoLabel: languageStrings.textPromoLabel,
    textPromoButtonApplyLabel: languageStrings.textPromoButtonApplyLabel,
    textPromoButtonRemoveLabel: languageStrings.textPromoButtonRemoveLabel,
    textLabelCustomCheckoutPolicy: languageStrings.textLabelCustomCheckoutPolicy,
    textCustomGdprDietaryTagOptinLabel: languageStrings.textCustomGdprDietaryTagOptinLabel,
    textCustomGdprDietaryTagOptinErrMsg: languageStrings.textCustomGdprDietaryTagOptinErrMsg,
    isModifyResMode: state.modifyReservation.get('isModifyResMode'),
    isModifyResUpgradesMode: state.modifyReservation.get('isModifyResUpgradesMode'),
    actual: state.modifyReservation.get('actual'),
    locale: state.venueInfo.locale,
    selectedLanguage: state.languages.selectedLanguage,
  }
}

const mapDispatchToProps = dispatch => ({
  onBookingPolicyClick: () => {
    dispatch(toggleField('agreedToBookingPolicy'))
  },
  onCustomCheckoutPolicyClick: () => {
    dispatch(toggleField('agreedCustomCheckoutPolicy'))
  },
  onVenueGroupMarketingOptInClick: () => {
    dispatch(toggleField('agreedToVenueGroupMarketingOptIn'))
  },
  onVenueSpecificMarketingOptInClick: () => {
    dispatch(toggleField('agreedToVenueSpecificMarketingOptIn'))
  },
  onVenueSmsMarketingOptInClick: () => {
    dispatch(toggleField('agreedToVenueSmsMarketingOptIn'))
  },
  onReservationSmsOptInClick: () => {
    dispatch(toggleField('agreedToReservationSmsOptIn'))
  },
  onTailoredCommunicationOptInClick: () => {
    dispatch(toggleField('agreedToTailoredCommunicationOptIn'))
  },
  onAgreedToDietaryOptinClick: () => {
    dispatch(toggleField('agreedToDietaryGdprOptinOn'))
  },
  onAboveAgeConsentClick: () => {
    dispatch(toggleField('agreedToAboveAgeConsentOn'))
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  removePromoCode: () => {
    dispatch(removePromoCode())
  },
  verifyPromoCode: () => {
    dispatch(verifyPromoCode())
  },
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
})

const CheckoutSummary = connect(mapStateToProps, mapDispatchToProps)(Radium(CheckoutSummaryComponent))

export default CheckoutSummary
